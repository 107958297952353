import { APP_SET_AUTH_USER, APP_SET_FAVORITE_FEEDS, APP_SET_GENERAL_FEED_NAME, APP_SET_MODAL_VIDEO_INSTRUCTION_SHOW_BOOL, APP_SET_RECOVERY_FEED_MODAL_SHOW_BOOL } from './../../actions/app/appTypes';

const initialState = {
    authUser: '',
    favoriteFeeds: '',
    isMobile: '991.98px',
    isDesktop: '1199.98px',
    generalFeedName: 'demo',
    modalVideoInstructionShowBool: false,
    recoveryFeedModalShowBool: false
};

export const appReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case APP_SET_AUTH_USER:
            return {...state, authUser: payload.authUser };
        case APP_SET_FAVORITE_FEEDS:
            return {...state, favoriteFeeds: payload.favoriteFeeds };
        case APP_SET_GENERAL_FEED_NAME:
            return {...state, generalFeedName: payload.generalFeedName };
        case APP_SET_MODAL_VIDEO_INSTRUCTION_SHOW_BOOL:
            return {...state, modalVideoInstructionShowBool: payload.modalVideoInstructionShowBool };
        case APP_SET_RECOVERY_FEED_MODAL_SHOW_BOOL:
            return {...state, recoveryFeedModalShowBool: payload.recoveryFeedModalShowBool };
        default:
            return state;
    }
};