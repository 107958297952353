import { useMediaQuery } from 'react-responsive';
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { feedSetAnyWindowIsOpen } from "../../../store/actions/feed/feedActions";

export const AnyWindowIsOpenDetector = React.memo(function AnyWindowIsOpenDetector(props){
    const dispatch = useDispatch();
    const imageViewerShowBool = useSelector((state) => state.feed.imageViewerShowBool, shallowEqual);
    const menuPostModalShowBool = useSelector((state) => state.feed.menuPostModalShowBool, shallowEqual);
    const commentModalShowBool = useSelector((state) => state.feed.commentModalShowBool, shallowEqual);
    const headerMobileModalFinderFeedShowBool = useSelector((state) => state.feed.headerMobileModalFinderFeedShowBool, shallowEqual);
    const headerMobileModalShowBool = useSelector((state) => state.feed.headerMobileModalShowBool, shallowEqual);
    const modalAvatarEditShowBool = useSelector((state) => state.feed.modalAvatarEditShowBool, shallowEqual);
    const shareModalShowBool = useSelector((state) => state.feed.shareModalShowBool, shallowEqual);
    const captchaModalShowBool = useSelector((state) => state.feed.captchaModalShowBool, shallowEqual);
    const uploadPostModalShowBool = useSelector((state) => state.feed.uploadPostModalShowBool, shallowEqual);
    const imageViewerFileInfoModalShowBool = useSelector((state) => state.feed.imageViewerFileInfoModalShowBool, shallowEqual);
    const editCategoriesModalShowBool = useSelector((state) => state.feed.editCategoriesModalShowBool, shallowEqual);
    const noPlaceModalShowBool = useSelector((state) => state.feed.noPlaceModalShowBool, shallowEqual);
    const generalSettingsModalShowBool = useSelector((state) => state.settings.generalSettingsModalShowBool, shallowEqual);
    const confidentialityModalShowBool = useSelector((state) => state.settings.confidentialityModalShowBool, shallowEqual);
    const favoriteFeedsModalShowBool = useSelector((state) => state.settings.favoriteFeedsModalShowBool, shallowEqual);
    const devicesModalShowBool = useSelector((state) => state.settings.devicesModalShowBool, shallowEqual);
    const changePasswordModalShowBool = useSelector((state) => state.settings.changePasswordModalShowBool, shallowEqual);
    const removeFeedModalShowBool = useSelector((state) => state.settings.removeFeedModalShowBool, shallowEqual);
    const whoCanUseMyFeedModalShowBool = useSelector((state) => state.settings.whoCanUseMyFeedModalShowBool, shallowEqual);
    const modalVideoInstructionShowBool = useSelector((state) => state.app.modalVideoInstructionShowBool, shallowEqual);
    const recoveryFeedModalShowBool = useSelector((state) => state.app.recoveryFeedModalShowBool, shallowEqual);
    const mobileMenuItemsShowBool = useSelector((state) => state.support.mobileMenuItemsShowBool, shallowEqual);



    const isMobileRedux = useSelector((state) => state.app.isMobile, shallowEqual);
    
    // const captchaModalShowBool = useSelector((state) => state.feed.captchaModalShowBool, shallowEqual);

    const isMobile = useMediaQuery({ query: `(min-width: 0px) and (max-width: ${isMobileRedux})` });
    
    useEffect(() => {
        // console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;','RERENDER AnyWindowIsOpenDetector');
        if (imageViewerShowBool || 
            menuPostModalShowBool || 
            commentModalShowBool ||
            uploadPostModalShowBool || 
            headerMobileModalFinderFeedShowBool ||
            headerMobileModalShowBool ||
            modalAvatarEditShowBool ||
            shareModalShowBool ||
            captchaModalShowBool ||
            imageViewerFileInfoModalShowBool ||
            editCategoriesModalShowBool ||
            noPlaceModalShowBool||
            (generalSettingsModalShowBool && isMobile) ||
            (confidentialityModalShowBool && isMobile) ||
            (favoriteFeedsModalShowBool && isMobile) ||
            (devicesModalShowBool && isMobile) ||
            changePasswordModalShowBool ||
            removeFeedModalShowBool || 
            whoCanUseMyFeedModalShowBool ||
            modalVideoInstructionShowBool ||
            recoveryFeedModalShowBool ||
            mobileMenuItemsShowBool){
            dispatch(feedSetAnyWindowIsOpen(true));
            document.body.classList.add("modalBlocOverflow");
        }
        else{
            dispatch(feedSetAnyWindowIsOpen(false));
            document.body.classList.remove("modalBlocOverflow");
        }
    })
});