import { changeModificatorForPost, deleteAvatarForUser, deleteCommentForPost, deletePost, favoritePostApi, getCommentsForPost, getFilesForPost, getPost, getPosts, privatePostApi, publicPostApi, setCustomAvatarForUser, unFavoritePostApi, getPostCategories } from '../../../services/filesService';
import { checkForExistenceInFavoriteFeeds, getUser } from '../../../services/userRequestsService';
import { FEED_SET_POSTS, FEED_SET_USER, FEED_SET_USERINFOPANEL_FAVORITE_CHECKER, FEED_SET_USERINFOPANEL_SEARCHWORD, FEED_SET_LASTID_OF_POST, FEED_SET_NEWPOSTSCOUNT, FEED_SET_NEW_POSTS_ID_MASSIVE, FEED_SET_NEW_POSTS_ID_MASSIVE_FROM_SERVER, FEED_SET_DELAY_NOTIFICATION_OF_NEW_POSTS, FEED_SET_USERINFOPANEL_PRIVATE_SHOW_CHECKER, FEED_SET_ITERABLE_BLOCKER_SCROLL_OF_POST, FEED_SET_ITERABLE_BLOCKER_SCROLL, FEED_SET_IMAGE_VIEWER_POST, FEED_SET_IMAGE_VIEWER_INDEX_FILE, FEED_SET_IMAGE_VIEWER_SHOW_BOOL, FEED_SET_IMAGE_VIEWER_INDEX_FILE_FOR_LOADING, FEED_SET_COMMENT_MODAL_SHOW_BOOL, FEED_SET_COMMENT_MODAL_POST_ID, FEED_SET_COMMENT_MODAL_COMMENTS, FEED_SET_SOCKET_ID, FEED_SET_MENU_POST_MODAL_SHOW_BOOL, FEED_SET_MENU_POST_MODAL, FEED_SET_ANY_WINDOW_IS_OPEN, FEED_SET_HEADER_MOBILE_MODAL_SHOW_BOOL, FEED_SET_CAPTCHA_MODAL_SHOW_BOOL, FEED_SET_ANY_CAPTCHA_NANO_ID_PASS, FEED_SET_HEADER_MOBILE_MODAL_FINDER_FEED_SHOW_BOOL, FEED_SET_USER_FOR_FAVORITE_FEEDS_SWITCHER, FEED_SET_MODAL_AVATAR_EDIT_SHOW_BOOL, FEED_SET_LAST_MOD_AVATAR_IMAGE, FEED_SET_SHARE_MODAL_SHOW_BOOL, FEED_SET_ENTITY_FOR_MODAL_SHARE, FEED_SET_UPLOAD_POST_MODAL_SHOW_BOOL, FEED_SET_NEW_POSTS_SAVED_AFTER_NOTIFICATION_FOR_NON_DUPLICATION, FEED_SET_IMAGE_VIEWER_FILE_INFO_MODAL_SHOW_BOOL, FEED_SET_CATEGORY_ID, FEED_SET_POST_CATEGORIES, FEED_SET_ARRAY_PROPERTY_FOR_POSTS, FEED_SET_EDIT_CATEGORIES_MODAL_SHOW_BOOL, FEED_SET_CATEGORY_INDEX, FEED_SET_NO_PLACE_MODAL_SHOW_BOOL, FEED_SET_AMOUNT_OF_MISSING_MEMORY, FEED_SET_POST_REQUEST_BOOL, FEED_SET_INITIAL_STATE } from './feedTypes';
import update from 'immutability-helper';
import { settingsSetChangePasswordModalShowBool, settingsSetConfidentialityModalShowBool, settingsSetDevicesModalShowBool, settingsSetFavoriteFeedsModalShowBool, settingsSetGeneralSettingsModalShowBool, settingsSetRemoveFeedModalShowBool, settingsSetWhoCanUseMyFeedModalShowBool } from '../settings/settingsActions';
import { appSetModalVideoInstruction, appSetRecoveryFeedModalShowBool } from '../app/appActions';
import { supportSetMobileMenuItemsShowBool } from '../support/supportActions';

export const feedSetUser = (user) =>{
    return{
        type: FEED_SET_USER,
        payload:{
            user
        }
    }

}

export const feedSetLastModAvatarImage = (lastModAvatarImage) =>{
    return{
        type: FEED_SET_LAST_MOD_AVATAR_IMAGE,
        payload:{
            lastModAvatarImage
        }
    }
}



export const feedSetUserForFavoriteFeedsSwitcher = (userForFavoriteFeedsSwitcher) =>{
    return{
        type: FEED_SET_USER_FOR_FAVORITE_FEEDS_SWITCHER,
        payload:{
            userForFavoriteFeedsSwitcher
        }
    }

}

export const feedSetUserInfoPanelFavoriteChecker = (favorite) => {
    return{
        type: FEED_SET_USERINFOPANEL_FAVORITE_CHECKER,
        payload:{
            favorite
        }
    }
}

export const feedSetUserInfoPanelPrivateShowChecker = (privateShow) => {
    return{
        type: FEED_SET_USERINFOPANEL_PRIVATE_SHOW_CHECKER,
        payload:{
            privateShow
        }
    }
}

export const feedSetUserInfoPanelSearchWord = (searchWord) => {
    return{
        type: FEED_SET_USERINFOPANEL_SEARCHWORD,
        payload:{
            searchWord
        }
    }
}

export const feedSetCategoryIndex = (categoryIndex) => {
    return{
        type: FEED_SET_CATEGORY_INDEX,
        payload:{
            categoryIndex
        }
    }
}

export const feedSetCategoryId = (categoryId) => {
    return{
        type: FEED_SET_CATEGORY_ID,
        payload:{
            categoryId
        }
    }
}

export const feedSetPostCategories = (postCategories) => {
    return{
        type: FEED_SET_POST_CATEGORIES,
        payload:{
            postCategories
        }
    }
}

export const feedSetArrayPropertyForPosts = (arrayPropertyForPosts) => {
    return{
        type: FEED_SET_ARRAY_PROPERTY_FOR_POSTS,
        payload:{
            arrayPropertyForPosts
        }
    }
}

export const feedSetPosts = (posts) => {
    return{
        type: FEED_SET_POSTS,
        payload:{
            posts
        }
    }
}

export const feedSetLastIdOfPost = (lastIdOfPost) => {
    return{
        type: FEED_SET_LASTID_OF_POST,
        payload:{
            lastIdOfPost
        }
    }
}

export const feedSetIterableBlockerScroll = (iterableBlockerScroll) => {
    return{
        type: FEED_SET_ITERABLE_BLOCKER_SCROLL,
        payload:{
            iterableBlockerScroll
        }
    }
}

export const feedSetNewPostsCount = (newPostsCount) => {
    return{
        type: FEED_SET_NEWPOSTSCOUNT,
        payload:{
            newPostsCount
        }
    }
}

export const feedSetNewPostsIdMassive = (newPostsIdMassive) => {
    return{
        type: FEED_SET_NEW_POSTS_ID_MASSIVE,
        payload:{
            newPostsIdMassive
        }
    }
}

export const feedSetNewPostsIdMassiveFromServer = (newPostsIdMassiveFromServer) => {
    return{
        type: FEED_SET_NEW_POSTS_ID_MASSIVE_FROM_SERVER,
        payload:{
            newPostsIdMassiveFromServer
        }
    }
}

export const feedSetDelayNotificationOfNewPosts = (delayNotificationOfNewPosts) => {
    return{
        type: FEED_SET_DELAY_NOTIFICATION_OF_NEW_POSTS,
        payload:{
            delayNotificationOfNewPosts
        }
    }
}

export const feedSetImageViewerShowBool = (imageViewerShowBool) => {
    return{
        type: FEED_SET_IMAGE_VIEWER_SHOW_BOOL,
        payload:{
            imageViewerShowBool
        }
    }
}

export const feedSetCommentModalShowBool = (commentModalShowBool) => {
    return{
        type: FEED_SET_COMMENT_MODAL_SHOW_BOOL,
        payload:{
            commentModalShowBool
        }
    }
}

export const feedSetMenuPostModalShowBool = (menuPostModalShowBool) => {
    return{
        type: FEED_SET_MENU_POST_MODAL_SHOW_BOOL,
        payload:{
            menuPostModalShowBool
        }
    }
}

export const feedSetCaptchaModalShowBool = (captchaModalShowBool) => {
    return{
        type: FEED_SET_CAPTCHA_MODAL_SHOW_BOOL,
        payload:{
            captchaModalShowBool
        }
    }
}

export const feedSetHeaderMobileModalShowBool = (headerMobileModalShowBool) => {
    return{
        type: FEED_SET_HEADER_MOBILE_MODAL_SHOW_BOOL,
        payload:{
            headerMobileModalShowBool
        }
    }
}

export const feedSetModalAvatarEditShowBool = (modalAvatarEditShowBool) => {
    return{
        type: FEED_SET_MODAL_AVATAR_EDIT_SHOW_BOOL,
        payload:{
            modalAvatarEditShowBool
        }
    }
}


export const feedSetHeaderMobileModalFinderFeedShowBool = (headerMobileModalFinderFeedShowBool) => {
    return{
        type: FEED_SET_HEADER_MOBILE_MODAL_FINDER_FEED_SHOW_BOOL,
        payload:{
            headerMobileModalFinderFeedShowBool
        }
    }
}

export const feedSetMenuPostModalId = (menuPostModal) => {
    return{
        type: FEED_SET_MENU_POST_MODAL,
        payload:{
            menuPostModal
        }
    }
}


export const feedSetImageViewer = (imageViewerPost) => {
    return{
        type: FEED_SET_IMAGE_VIEWER_POST,
        payload:{
            imageViewerPost
        }
    }
}

export const feedSetImageViewerIndexFile = (imageViewerIndexFile) => {
    return{
        type: FEED_SET_IMAGE_VIEWER_INDEX_FILE,
        payload:{
            imageViewerIndexFile
        }
    }
}

export const feedSetImageViewerIndexFileForLoading = (imageViewerIndexFileForLoading) => {
    return{
        type: FEED_SET_IMAGE_VIEWER_INDEX_FILE_FOR_LOADING,
        payload:{
            imageViewerIndexFileForLoading
        }
    }
}

export const feedSetCommentModalPostId = (commentModalPostId) => {
    return{
        type: FEED_SET_COMMENT_MODAL_POST_ID,
        payload:{
            commentModalPostId
        }
    }
}

export const feedSetCommentModalComments = (commentModalComments) => {
    return{
        type: FEED_SET_COMMENT_MODAL_COMMENTS,
        payload:{
            commentModalComments
        }
    }
}

export const feedSetSocketId = (socketId) => {
    return{
        type: FEED_SET_SOCKET_ID,
        payload:{
            socketId
        }
    }
}


export const feedSetAnyWindowIsOpen = (anyWindowIsOpen) => {
    return{
        type: FEED_SET_ANY_WINDOW_IS_OPEN,
        payload:{
            anyWindowIsOpen
        }
    }
}

export const feedSetCaptchaNanoIdPass = (captchaNanoIdPass) => {
    return{
        type: FEED_SET_ANY_CAPTCHA_NANO_ID_PASS,
        payload:{
            captchaNanoIdPass
        }
    }
}

export const feedSetShareModalShowBool = (shareModalShowBool) => {
    return{
        type: FEED_SET_SHARE_MODAL_SHOW_BOOL,
        payload:{
            shareModalShowBool
        }
    }
}

export const feedSetEntityForModalShare = (entityForModalShare) => {
    return{
        type: FEED_SET_ENTITY_FOR_MODAL_SHARE,
        payload:{
            entityForModalShare
        }
    }
}

export const feedSetUploadPostModalShowBool = (uploadPostModalShowBool) => {
    return{
        type: FEED_SET_UPLOAD_POST_MODAL_SHOW_BOOL,
        payload:{
            uploadPostModalShowBool
        }
    }
}

export const feedSetImageViewerFileInfoModalShowBool = (imageViewerFileInfoModalShowBool) => {
    return{
        type: FEED_SET_IMAGE_VIEWER_FILE_INFO_MODAL_SHOW_BOOL,
        payload:{
            imageViewerFileInfoModalShowBool
        }
    }
}

export const feedSetEditCategoriesModalShowBool = (editCategoriesModalShowBool) => {
    return{
        type: FEED_SET_EDIT_CATEGORIES_MODAL_SHOW_BOOL,
        payload:{
            editCategoriesModalShowBool
        }
    }
}

export const feedSetNoPlaceModalShowBool = (noPlaceModalShowBool) => {
    return{
        type: FEED_SET_NO_PLACE_MODAL_SHOW_BOOL,
        payload:{
            noPlaceModalShowBool
        }
    }
}

export const feedSetAmountOfMissingMemory = (amountOfMissingMemory) => {
    return{
        type: FEED_SET_AMOUNT_OF_MISSING_MEMORY,
        payload:{
            amountOfMissingMemory
        }
    }
}

export const feedSetPostRequestBool = (postRequestBool) => {
    return{
        type: FEED_SET_POST_REQUEST_BOOL,
        payload:{
            postRequestBool
        }
    }
}

export const feedSetInitialState = () => {
    return{
        type: FEED_SET_INITIAL_STATE
    }
}

export const feedAsyncSetPosts = (continueBool) =>{
    return function (dispatch, getState){
        console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;','Get Posts');
        dispatch(feedSetPostRequestBool(true));
        if(!continueBool){
            dispatch(feedSetPosts([]));
        }
        setTimeout(() => {
            var lastId = 0;
            var posts = getState().feed.posts;
            var iterableBlockerScroll = getState().feed.iterableBlockerScroll;
            // console.log(posts, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            if (continueBool && posts.length > 0){
                lastId = posts[posts.length - 1].id;
            }
            // else{
            //     dispatch(feedSetPosts([]));
            // }
            getPosts(getState().feed.user.id, lastId, getState().feed.favorite, getState().feed.privateShow, getState().feed.searchWord, getState().feed.categoryId)
                .then(response => {
                    // console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;','Get Posts!!!!!!!');
                    dispatch(feedSetPostRequestBool(false));
                    // console.log(response.data);
                    var newLoadPosts = response.data;
                    if (newLoadPosts.length > 0){
                        dispatch(feedSetLastIdOfPost(newLoadPosts[newLoadPosts.length - 1].id));
                        dispatch(feedSetIterableBlockerScroll(iterableBlockerScroll + 1));
                    }
                    // console.log(props.user.id);
                    if (lastId){
                        if (newLoadPosts.length > 0){
                            dispatch(feedSetPosts(getState().feed.posts.concat(newLoadPosts)));
                            // setFlashPostId(0);
                        }
                    }
                    else{
                        // setPosts([...response.data].concat(postsRef));
                        dispatch(feedSetPosts(newLoadPosts));
                        window.scrollTo(0, 0);
                    }
                })
                .catch(e => {
                    console.log(e);
                    // dispatch(feedSetPostRequestBool(false));
                    setTimeout(() => {
                        dispatch(feedSetIterableBlockerScroll(iterableBlockerScroll + 1));
                    }, 1000);
                });
        }, 1)
    }
}

export const feedAsyncSetPostsFirstWithoutFilters = () =>{
    return function (dispatch){
        dispatch(feedSetNewPostsCount(0));
        dispatch(feedSetUserInfoPanelFavoriteChecker(false));
        dispatch(feedSetUserInfoPanelPrivateShowChecker(false));
        dispatch(feedSetUserInfoPanelSearchWord(""));
        dispatch(feedAsyncSetPosts(false));
    }
}

export const feedAsyncAddNewPost = (postId, editBool) =>{
    return function (dispatch, getState){
        getPost(postId)
            .then(response => {
                // console.log(posts,"posts in getNewPosts");
                var newPosts = [...getState().feed.posts];
                var post = response.data;

                //limit post files for show
                post.files = post.files.slice(0, 5);

                post['flash'] = true;
                if (editBool){
                    const i = newPosts.findIndex(x => x.id === postId);
                    if (i !== -1){
                        newPosts.splice(i, 1, post)
                        dispatch(feedSetPosts(newPosts));
                    }
                }
                else{
                    if (getState().feed.favorite || getState().feed.searchWord || getState().feed.privateShow){
                        dispatch(feedSetUserInfoPanelFavoriteChecker(false));
                        dispatch(feedSetUserInfoPanelPrivateShowChecker(false));
                        dispatch(feedSetUserInfoPanelSearchWord(""));
                        dispatch(feedAsyncSetPosts(false));
                    }
                    else{
                        var pinIndex = -1;
                        if (!post.pinned){
                            for (let i = 0; i < newPosts.length; i++){
                                if (newPosts[i]['pinned'] == true){
                                    pinIndex = i+1;
                                }
                                else{
                                    break;
                                }
                            }
                        }
                        if (pinIndex !== -1){
                            console.log(pinIndex,"pinIndex");
                            newPosts.splice(pinIndex, 0, post);
                            dispatch(feedSetPosts(newPosts));
                        }
                        else{
                            dispatch(feedSetPosts([post].concat(newPosts)));
                        }
                    }   
                }
                dispatch(feedAsyncSetUser());
                dispatch(feedAsyncSetPostCategories());
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export const feedFlashDeleteOfPost = (postId) =>{
    return function (dispatch, getState){
        var newPosts = [...getState().feed.posts];
        const i = newPosts.findIndex(x => x.id === postId);
        if (i !== -1){
            newPosts.splice(i, 1, {...newPosts[i]})
            newPosts[i]['flash'] = false;
            dispatch(feedSetPosts(newPosts));
        }
    }
}

export const feedSetNewPostsCountIncrement = () =>{
    return function (dispatch, getState){
        function include(arr, obj) {
            for (var i = 0; i < arr.length; i++) {
              if (arr[i] == obj) return true;
            }
        }
        setTimeout(() => {
            
            const newPostsIdMassive = getState().feed.newPostsIdMassive;
            const newPostsIdMassiveFromServer = getState().feed.newPostsIdMassiveFromServer;
            const delayNotificationOfNewPosts = getState().feed.delayNotificationOfNewPosts;
            const newPosts = getState().feed.posts;

            if (!delayNotificationOfNewPosts){
                for (let i=0 ; i< newPostsIdMassiveFromServer.length; i++){
                    const x = include(newPostsIdMassive, newPostsIdMassiveFromServer[i].postId);
                    console.log(x,' i feedSetNewPostsCountIncrement');
                    const j = newPosts.findIndex(x2 => x2.id === newPostsIdMassiveFromServer[i].postId);
                    console.log(j,' i feedSetNewPostsCountIncrement222222222222222');
                    if (!x && j == -1){
                        if (!newPostsIdMassiveFromServer[i].afterEditing){
                            const newPostsCount = getState().feed.newPostsCount;
                            dispatch(feedSetNewPostsCount(newPostsCount + 1));
                        }   
                    }
                    else{
                        if (j != -1 && newPostsIdMassiveFromServer[i].updateRequirement){
                            dispatch(feedSetReplacingPostOnFeed(newPostsIdMassiveFromServer[i].postId));
                        }
                    }
                }
                dispatch(feedSetNewPostsIdMassiveFromServer(""));
            }
        },1);
        // console.log(getState().feed.posts,'posts_socket_actual');
    }
}

export const feedSetReplacingPostOnFeed = (postId) =>{
    return function (dispatch, getState){
    getPost(postId)
        .then(response => {
            var post = response.data;

            var newPosts = [...getState().feed.posts];

            const i = newPosts.findIndex(x => x.id === postId);
            if (i !== -1){
                // newPosts.splice(i, 1);

                //limit post files for replace
                var imageViewerPost = getState().feed.imageViewerPost;
                if (imageViewerPost.id == post.id){
                    dispatch(feedSetImageViewer(JSON.parse(JSON.stringify(post))));
                }
                if (!newPosts[i].showAllFilesBool && post.files.length > 5){
                    post.files = post.files.slice(0, 5);
                }
                else{
                    post.showAllFilesBool = newPosts[i].showAllFilesBool
                }
                if (newPosts[i].showFullTextBool){
                    post.showFullTextBool = newPosts[i].showFullTextBool;
                }
                newPosts.splice(i, 1, {...post})
                dispatch(feedSetPosts(newPosts));
                // dispatch(feedAsyncSetUser());
            }
        })
        .catch(e => {
            console.log(e);
        });
    }
}

export const feedSetNewPostsIdMassiveAdd = (postId) =>{
    return function (dispatch, getState){
        let mas = [...getState().feed.newPostsIdMassive];
        mas.push(postId);
        dispatch(feedSetNewPostsIdMassive(mas));
    }
}

export const feedSetNewPostsIdMassiveFromServerAdd = (msg) =>{
    return function (dispatch, getState){
        let mas = [...getState().feed.newPostsIdMassiveFromServer];
        mas.push(msg);
        dispatch(feedSetNewPostsIdMassiveFromServer(mas));
    }
}

export const feedAsyncSetShowAllFilesOfPost = (postId) =>{
    return function (dispatch, getState){
        getFilesForPost(postId)
            .then(response => {
                var newPosts = [...getState().feed.posts];

                const i = newPosts.findIndex(x => x.id === postId);
                if (i !== -1){
                    newPosts.splice(i, 1, {...newPosts[i]})
                    newPosts[i]['files'] = response.data;
                    newPosts[i]["showAllFilesBool"] = true;
                    dispatch(feedSetPosts(newPosts));
                }
            })
            .catch(e => {
                console.log(e);
            });
    } 
}

export const feedAsyncSetShowAllCommentOfPost = (postId) =>{
    return function (dispatch, getState){
        return getCommentsForPost(postId)
            .then(response => {
                console.log(response.data);
                if (response.data.length > 0){
                    dispatch(feedSetCommentModalComments(response.data))
                }
                // var newPosts = [...getState().feed.posts];

                // const i = newPosts.findIndex(x => x.id === postId);
                // if (i !== -1){
                //     newPosts.splice(i, 1, {...newPosts[i]})
                //     newPosts[i]['comments'] = response.data;
                //     newPosts[i]['commentCounterOfPost'] = response.data.length;
                //     dispatch(feedSetPosts(newPosts));
                //     return response;
                // }
                return response;
            })
            .catch(e => {
                console.log(e);
            });
    } 
}

export const addNewCommentForRender = (newComment, serverComingComment = false) => {
    return function (dispatch, getState){
        var newPosts = [...getState().feed.posts];

        

        const i = newPosts.findIndex(x => x.id === newComment.post);
        if (i !== -1){
            newPosts.splice(i, 1, {...newPosts[i]})
            if (newPosts[i]['commentCounterOfPost'] == 0){
                console.log('should add first comment')
                newPosts[i].comments = newPosts[i].comments.concat(newComment);
            }
            newPosts[i]['commentCounterOfPost'] += 1;
            dispatch(feedSetPosts(newPosts));
        }
        if (getState().feed.commentModalShowBool){
            if (getState().feed.commentModalPostId == newComment.post){
                var comments = [...getState().feed.commentModalComments];
                var tempComment = JSON.parse(JSON.stringify(newComment));
                tempComment.flash = true;
                dispatch(feedSetCommentModalComments(comments.concat(tempComment)));
            }
        }    
    }
} 

export const feedFlashDeleteOfComment = (commentId) => {
    return function (dispatch, getState){
        var comments = [...getState().feed.commentModalComments];
        const i = comments.findIndex(x => x.id === commentId);
        if (i !== -1){
            comments.splice(i, 1, {...comments[i]})
            comments[i]['flash'] = false;
            dispatch(feedSetCommentModalComments(comments));
        }
    }
}



//---
export const feedAsyncDeleteComment = (commentModalPostId, commentId) => {
    return function (dispatch, getState){

        deleteCommentForPost(commentModalPostId, commentId)
            .then(response => {
                // console.log(response.data);

                var newCommentModalComments = [...getState().feed.commentModalComments];

                var newPosts = [...getState().feed.posts];
                const j = newPosts.findIndex(x => x.id === commentModalPostId);
                const i = newCommentModalComments.findIndex(x => x.id === commentId);
                if (j !== -1){
                    newPosts.splice(j, 1, {...newPosts[j]})
                    if (newPosts[j]['commentCounterOfPost'] == 1){
                        console.log('should delete first comment')
                        newPosts[j].comments = [];
                    }
                    
                    if (i == 0 && newCommentModalComments.length > 1){
                        newPosts[j].comments = [];
                        newPosts[j].comments = newPosts[j].comments.concat(newCommentModalComments[1]);
                    }
                    newPosts[j]['commentCounterOfPost'] -= 1;
                    dispatch(feedSetPosts(newPosts));
                }
                if (i !== -1){
                    // newPosts.splice(i, 1, {...newPosts[i], delete: true })
                    newCommentModalComments.splice(i, 1);
                    dispatch(feedSetCommentModalComments(newCommentModalComments));
                }
                
            })
            .catch(e => {
                console.log(e);
            });
    }
}

//---


export const feedAsyncChangeModificatorForPost = (postId, mode, modeBool) =>{
    return function (dispatch, getState){
        var socketId = getState().feed.socketId;
        changeModificatorForPost(postId, mode, modeBool, socketId)
            .then(response => {
                console.log(response.data);
                var newPosts = [...getState().feed.posts];

                const i = newPosts.findIndex(x => x.id === postId);
                if (i !== -1){
                    newPosts.splice(i, 1, {...newPosts[i]})
                    switch (mode) {
                        case "pinned":
                            newPosts[i]['pinned'] = modeBool;
                            break;
                        case "private":
                            newPosts[i]['private'] = modeBool;
                            break;
                        case "favorite":
                            newPosts[i]['favorite'] = modeBool;
                            break;
                        default:
                            break;
                    }
                    
                    dispatch(feedSetPosts(newPosts));
                }
            })
            .catch(e => {
                console.log(e);
            });
    } 
}

export const feedAsyncDeletePost = (postId) =>{
    return function (dispatch, getState){
        deletePost(postId)
            .then(response => {
                console.log(response.data);
                var newPosts = [...getState().feed.posts];

                const i = newPosts.findIndex(x => x.id === postId);
                if (i !== -1){
                    // newPosts.splice(i, 1, {...newPosts[i], delete: true })
                    newPosts.splice(i, 1)

                    dispatch(feedSetPosts(newPosts));
                    dispatch(feedAsyncSetUser());
                    dispatch(feedAsyncSetPostCategories());
                }
            })
            .catch(e => {
                console.log(e);
            });
    } 
}

export const feedAsyncSetUser = (username) =>{
    return function (dispatch, getState){
        if (!username){
            username =  getState().feed.user.username;
        }
        if (username){
            getUser(username)
                .then(response => {
                    // if(response.data){
                    //     console.log("true");
                    // }
                    // else{
                    //     console.log("false");
                    // }
                    dispatch(feedSetUser(response.data));
                    console.log(response.data);
                    // console.log(asd,"asynctest2");
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
}

export const feedAsyncSetUserForFavoriteFeedsSwitcher = (userId) =>{
    return function (dispatch){
        if (userId){
            checkForExistenceInFavoriteFeeds(userId)
                .then(response => {
                    if (response.data.exist){
                        dispatch(feedSetUserForFavoriteFeedsSwitcher(false));
                    }
                    else{
                        dispatch(feedSetUserForFavoriteFeedsSwitcher(true));
                    }
                    
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
}

export const feedSetImageViewerPostFind = (postId) =>{
    return function (dispatch, getState){
        var newPosts = [...getState().feed.posts];
        const i = newPosts.findIndex(x => x.id === postId);
        if (i !== -1){
            dispatch(feedSetImageViewer(newPosts[i]));
        }
    }
}

export const feedAsyncSetAdditionalLoadingAllFilesOfPost = (postId, forLoading = false) =>{
    return function (dispatch, getState){
        getFilesForPost(postId)
            .then(response => {
                var imageViewerPost = JSON.parse(JSON.stringify(getState().feed.imageViewerPost))
                imageViewerPost.files = response.data;
                dispatch(feedSetImageViewer(imageViewerPost));
                if (forLoading){
                    dispatch(feedSetImageViewerIndexFileForLoading(getState().feed.imageViewerIndexFileForLoading + 1));
                }
                else{
                    dispatch(feedSetImageViewerIndexFile(getState().feed.imageViewerIndexFile + 1));
                }
            })
            .catch(e => {
                console.log(e);
            });
    } 
}


export const feedsSetShowFullTextCurrentPost = (postId) =>{
    return function (dispatch, getState){
        var newPosts = [...getState().feed.posts];

        const i = newPosts.findIndex(x => x.id === postId);
        if (i !== -1){
            newPosts.splice(i, 1, {...newPosts[i], showFullTextBool: true })
            dispatch(feedSetPosts(newPosts));
        }
    } 
}


export const feedAsyncAddAvatarImage = (file) =>{
    return function (dispatch, getState){
        dispatch(feedSetModalAvatarEditShowBool(false));
        var socketId = getState().feed.socketId;
        setCustomAvatarForUser(file, socketId)
            .then(response => {
                if (response.data.avatar == 'success'){
                    console.log("AVATAR CREATED");
                    dispatch(feedSetLastModAvatarImage(Date.now()));
                    dispatch(feedAsyncSetUser());
                    // dispatch(feedSetModalAvatarEditShowBool(false));
                }
                else{
                    console.log("AVATAR FAIL");
                }
            })
            .catch(e => {
                console.log(e);
            });
    } 
}

export const feedAsyncDeleteAvatarImage = (file) =>{
    return function (dispatch, getState){
        dispatch(feedSetModalAvatarEditShowBool(false));
        var socketId = getState().feed.socketId;
        deleteAvatarForUser(socketId)
            .then(response => {
                if (response.data.avatar == 'success'){
                    console.log("AVATAR DELETE");
                    dispatch(feedSetLastModAvatarImage(Date.now()));
                    dispatch(feedAsyncSetUser());
                    // dispatch(feedSetModalAvatarEditShowBool(false));
                }
                else{
                    console.log("AVATAR FAIL");
                }
            })
            .catch(e => {
                console.log(e);
            });
    } 
}



//------------------------------- Update Post After Click Button Share------------------------------

export const feedAsyncUpdatePostAfterClickButtonShare = (url, entityForModalShare) =>{
    return function (dispatch, getState){
        var newPosts = [...getState().feed.posts];
        // console.log("update", url);
        if (entityForModalShare.typeEntity == "Post"){
            const i = newPosts.findIndex(x => x.id === entityForModalShare.id);
            if (i !== -1){
                const newData = update(newPosts, {
                    [i]:{url:{$set: url}}
                });
                dispatch(feedSetPosts(newData));
            }
        }
        else{
            const i = newPosts.findIndex(x => x.id === entityForModalShare.post);
            const j = newPosts[i]['files'].findIndex(x => x.id === entityForModalShare.id);
            if (i!== -1 && j!== -1){

                const newData = update(newPosts, 
                    {[i]:{files:{[j]:{url: {$set: url}}}}
                    
                });
                dispatch(feedSetPosts(newData));

                //imageViewerPost update
                var newImageViewerPost = getState().feed.imageViewerPost;
                if (newImageViewerPost.id == entityForModalShare.post){
                    const y = newImageViewerPost['files'].findIndex(x => x.id === entityForModalShare.id);
                    if (y!== -1){
                        const newDataForViewer = update(newImageViewerPost, 
                            {files:{[y]:{url: {$set: url}}} 
                        });
                        dispatch(feedSetImageViewer(newDataForViewer));
                    }
                }
            }
        }
    } 
}
//--------------------------------------------------------------------------------------------------

export const feedAsyncSetPostCategories = () =>{
    return function (dispatch, getState){
        let userId = getState().feed.user.id;
        getPostCategories(userId)
            .then(response => {
                let postCategories = response.data;
                dispatch(feedSetPostCategories(postCategories));
                if (postCategories.countCategories > 0){
                    // let colorSet = {1: "#ad6417", 2 : "#251c1c", 3 : "#2c3837"}
                    var dictinaryProperty = {}
                    postCategories.categories.forEach(category => 
                        {
                            dictinaryProperty[category.id] = category.name.charAt(0).toUpperCase() + category.name.slice(1).toLowerCase();
                        });
                    console.log(dictinaryProperty, 'dictinaryProperty');
                    dispatch(feedSetArrayPropertyForPosts(dictinaryProperty));
                }
                
            })
            .catch(e => {
                console.log(e);
            });

    } 
}


export const feedSetCloseAllModalWindow = () =>{
    return function (dispatch, getState){
        dispatch(feedSetImageViewerShowBool(false));
        dispatch(feedSetImageViewerIndexFile(-1));
        dispatch(feedSetMenuPostModalShowBool(false));
        dispatch(feedSetCommentModalShowBool(false));
        dispatch(feedSetHeaderMobileModalFinderFeedShowBool(false));
        dispatch(feedSetHeaderMobileModalShowBool(false));
        dispatch(feedSetModalAvatarEditShowBool(false));
        dispatch(feedSetShareModalShowBool(false));
        dispatch(feedSetEntityForModalShare(""));
        dispatch(feedSetCaptchaModalShowBool(false));
        dispatch(feedSetUploadPostModalShowBool(false));
        dispatch(feedSetImageViewerFileInfoModalShowBool(false));
        dispatch(feedSetEditCategoriesModalShowBool(false));
        dispatch(feedSetNoPlaceModalShowBool(false));
        dispatch(feedSetAmountOfMissingMemory(0));
        dispatch(appSetModalVideoInstruction(false));
        dispatch(appSetRecoveryFeedModalShowBool(false));

        // ------------------settings----------------------

        dispatch(settingsSetGeneralSettingsModalShowBool(false));
        dispatch(settingsSetConfidentialityModalShowBool(false));
        dispatch(settingsSetFavoriteFeedsModalShowBool(false));
        dispatch(settingsSetDevicesModalShowBool(false));
        dispatch(settingsSetChangePasswordModalShowBool(false));
        dispatch(settingsSetRemoveFeedModalShowBool(false));
        dispatch(settingsSetWhoCanUseMyFeedModalShowBool(false));

        // ------------------support----------------------
        
        dispatch(supportSetMobileMenuItemsShowBool(false));
        
        // dispatch(feedSetAnyWindowIsOpen(false));

    } 
}