import { SUPPORT_SET_MENU_ITEM, SUPPORT_SET_MOBILE_MENU_ITEMS_SHOW_BOOL, SUPPORT_SET_SCROLL_TO_ELEMENT } from "./supportTypes"

export const supportSetMenuItem = (menuItem) =>{
    return{
        type: SUPPORT_SET_MENU_ITEM,
        payload:{
            menuItem
        }
    }
}

export const supportSetScrollToElement = (scrollToElement) =>{
    return{
        type: SUPPORT_SET_SCROLL_TO_ELEMENT,
        payload:{
            scrollToElement
        }
    }
}

export const supportSetMobileMenuItemsShowBool = (mobileMenuItemsShowBool) =>{
    return{
        type: SUPPORT_SET_MOBILE_MENU_ITEMS_SHOW_BOOL,
        payload:{
            mobileMenuItemsShowBool
        }
    }
}