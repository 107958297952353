import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';

import { Provider } from 'react-redux';
import { store } from './store/store';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom'
import SuspenseLoading from './components/general/suspenseLoading/suspenseLoading';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}


ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<SuspenseLoading/>}>
      <Router>
        {/* <React.StrictMode> */}
          <App />
        {/* </React.StrictMode> */}
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById('root')
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
