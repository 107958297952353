import {combineReducers} from 'redux';
import { appReducer } from './app/appReducer';
import { feedReducer } from './feed/feedReducer';
import { settingsReducer } from './settings/settingsReducer';
import { supportReducer } from './support/supportReducer';


export const rootReducer = combineReducers({
    app: appReducer,
    feed: feedReducer,
    settings: settingsReducer,
    support: supportReducer
})

// const initialState = {
//     counter:0
// }

// export function rootReducer(state= initialState, action){
//     switch(action.type){
//         case 'INCREMENT':
//             return {counter: state.counter + action.payload.val}
//         case 'DECREMENT':
//             return {counter:state.counter - 1}
//         default:
//             return state;
//     }
// }