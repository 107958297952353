import React, { Fragment } from "react";
import './suspenseLoading.scss';

import SVG from 'react-inlinesvg';
import logoS from '../../feeds/media/welcomePage/logoS.svg';

const SuspenseLoading = React.memo( function SuspenseLoading() {

    return(
        <Fragment>
            {console.log('suspenseLoading rerender ')}
            
                <div className="suspenseLoading">

                    <div className="suspenseLoading__wrapper">
                        <div className="suspenseLoading__loader">
                            <SVG src={logoS} className="suspenseLoading__loading-animation__path"/>
                        </div>
                    </div>

                </div>

        </Fragment>
        
    )
});

export default SuspenseLoading;
