export const FEED_SET_USER = 'FEED_SET_USER';
export const FEED_SET_LAST_MOD_AVATAR_IMAGE = 'FEED_SET_LAST_MOD_AVATAR_IMAGE';
export const FEED_SET_USER_FOR_FAVORITE_FEEDS_SWITCHER = "FEED_SET_USER_FOR_FAVORITE_FEEDS_SWITCHER";
export const FEED_SET_USERINFOPANEL_FAVORITE_CHECKER = 'FEED_SET_USERINFOPANEL_FAVORITE_CHECKER';
export const FEED_SET_USERINFOPANEL_PRIVATE_SHOW_CHECKER = 'FEED_SET_USERINFOPANEL_PRIVATE_SHOW_CHECKER';
export const FEED_SET_USERINFOPANEL_SEARCHWORD = 'FEED_SET_USERINFOPANEL_SEARCHWORD';
export const FEED_SET_CATEGORY_ID = 'FEED_SET_CATEGORY_ID';
export const FEED_SET_CATEGORY_INDEX = 'FEED_SET_CATEGORY_INDEX';
export const FEED_SET_POST_CATEGORIES = 'FEED_SET_POST_CATEGORIES';
export const FEED_SET_ARRAY_PROPERTY_FOR_POSTS = 'FEED_SET_ARRAY_COLORS_FOR_POSTS';
export const FEED_SET_POSTS = 'FEED_SET_POSTS';
export const FEED_SET_LASTID_OF_POST = 'FEED_SET_LASTID_OF_POST';
export const FEED_SET_ITERABLE_BLOCKER_SCROLL = 'FEED_SET_ITERABLE_BLOCKER_SCROLL';
export const FEED_SET_NEWPOSTSCOUNT = 'FEED_SET_NEWPOSTSCOUNT';
export const FEED_SET_NEW_POSTS_ID_MASSIVE = 'FEED_SET_NEW_POSTS_ID_MASSIVE';
export const FEED_SET_NEW_POSTS_ID_MASSIVE_FROM_SERVER = 'FEED_SET_NEW_POSTS_ID_MASSIVE_FROM_SERVER';
export const FEED_SET_DELAY_NOTIFICATION_OF_NEW_POSTS = 'FEED_SET_DELAY_NOTIFICATION_OF_NEW_POSTS';
export const FEED_SET_IMAGE_VIEWER_POST = 'FEED_SET_IMAGE_VIEWER_POST';
export const FEED_SET_IMAGE_VIEWER_INDEX_FILE = 'FEED_SET_IMAGE_VIEWER_INDEX_FILE';
export const FEED_SET_IMAGE_VIEWER_INDEX_FILE_FOR_LOADING = 'FEED_SET_IMAGE_VIEWER_INDEX_FILE_FOR_LOADING';
export const FEED_SET_IMAGE_VIEWER_SHOW_BOOL = 'FEED_SET_IMAGE_VIEWER_SHOW_BOOL';
export const FEED_SET_COMMENT_MODAL_SHOW_BOOL = 'FEED_SET_COMMENT_MODAL_SHOW_BOOL';
export const FEED_SET_MENU_POST_MODAL_SHOW_BOOL = 'FEED_SET_MENU_POST_MODAL_SHOW_BOOL';
export const FEED_SET_CAPTCHA_MODAL_SHOW_BOOL = 'FEED_SET_CAPTCHA_MODAL_SHOW_BOOL';
export const FEED_SET_MENU_POST_MODAL = 'FEED_SET_MENU_POST_MODAL';
export const FEED_SET_COMMENT_MODAL_POST_ID = 'FEED_SET_COMMENT_MODAL_POST_ID';
export const FEED_SET_COMMENT_MODAL_COMMENTS = 'FEED_SET_COMMENT_MODAL_COMMENTS';
export const FEED_SET_SOCKET_ID = 'FEED_SET_SOCKET_ID';
export const FEED_SET_ANY_WINDOW_IS_OPEN = 'FEED_SET_ANY_WINDOW_IS_OPEN';
export const FEED_SET_HEADER_MOBILE_MODAL_SHOW_BOOL = 'FEED_SET_HEADER_MOBILE_MODAL_SHOW_BOOL';
export const FEED_SET_ANY_CAPTCHA_NANO_ID_PASS = 'FEED_SET_ANY_CAPTCHA_NANO_ID_PASS';
export const FEED_SET_HEADER_MOBILE_MODAL_FINDER_FEED_SHOW_BOOL = "FEED_SET_HEADER_MOBILE_MODAL_FINDER_FEED_SHOW_BOOL";
export const FEED_SET_MODAL_AVATAR_EDIT_SHOW_BOOL = "FEED_SET_MODAL_AVATAR_EDIT_SHOW_BOOL";
export const FEED_SET_SHARE_MODAL_SHOW_BOOL = "FEED_SET_SHARE_MODAL_SHOW_BOOL";
export const FEED_SET_ENTITY_FOR_MODAL_SHARE = "FEED_SET_ENTITY_FOR_MODAL_SHARE";
export const FEED_SET_UPLOAD_POST_MODAL_SHOW_BOOL = "FEED_SET_UPLOAD_POST_MODAL_SHOW_BOOL";
export const FEED_SET_IMAGE_VIEWER_FILE_INFO_MODAL_SHOW_BOOL = "FEED_SET_IMAGE_VIEWER_FILE_INFO_MODAL_SHOW_BOOL";
export const FEED_SET_EDIT_CATEGORIES_MODAL_SHOW_BOOL = "FEED_SET_EDIT_CATEGORIES_MODAL_SHOW_BOOL";
export const FEED_SET_NO_PLACE_MODAL_SHOW_BOOL = "FEED_SET_NO_PLACE_MODAL_SHOW_BOOL";
export const FEED_SET_AMOUNT_OF_MISSING_MEMORY = "FEED_SET_AMOUNT_OF_MISSING_MEMORY";
export const FEED_SET_POST_REQUEST_BOOL = "FEED_SET_POST_REQUEST_BOOL";
export const FEED_SET_INITIAL_STATE = "FEED_SET_INITIAL_STATE";














