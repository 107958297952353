import { FEED_SET_POSTS, FEED_SET_USER, FEED_SET_USERINFOPANEL_FAVORITE_CHECKER, FEED_SET_USERINFOPANEL_SEARCHWORD, FEED_SET_LASTID_OF_POST, FEED_SET_NEWPOSTSCOUNT, FEED_SET_NEW_POSTS_ID_MASSIVE, FEED_SET_NEW_POSTS_ID_MASSIVE_FROM_SERVER, FEED_SET_DELAY_NOTIFICATION_OF_NEW_POSTS, FEED_SET_USERINFOPANEL_PRIVATE_SHOW_CHECKER, FEED_SET_ITERABLE_BLOCKER_SCROLL, FEED_SET_IMAGE_VIEWER_POST, FEED_SET_IMAGE_VIEWER_INDEX_FILE, FEED_SET_IMAGE_VIEWER_SHOW_BOOL, FEED_SET_IMAGE_VIEWER_INDEX_FILE_FOR_LOADING, FEED_SET_COMMENT_MODAL_SHOW_BOOL, FEED_SET_MENU_POST_MODAL_SHOW_BOOL,FEED_SET_MENU_POST_MODAL,FEED_SET_COMMENT_MODAL_POST_ID, FEED_SET_COMMENT_MODAL_COMMENTS, FEED_SET_SOCKET_ID, FEED_SET_ANY_WINDOW_IS_OPEN, FEED_SET_HEADER_MOBILE_MODAL_SHOW_BOOL, FEED_SET_CAPTCHA_MODAL_SHOW_BOOL, FEED_SET_ANY_CAPTCHA_NANO_ID_PASS, FEED_SET_HEADER_MOBILE_MODAL_FINDER_FEED_SHOW_BOOL, FEED_SET_USER_FOR_FAVORITE_FEEDS_SWITCHER, FEED_SET_MODAL_AVATAR_EDIT_SHOW_BOOL, FEED_SET_LAST_MOD_AVATAR_IMAGE, FEED_SET_ENTITY_FOR_MODAL_SHARE, FEED_SET_SHARE_MODAL_SHOW_BOOL, FEED_SET_UPLOAD_POST_MODAL_SHOW_BOOL, FEED_SET_NEW_POSTS_SAVED_AFTER_NOTIFICATION_FOR_NON_DUPLICATION, FEED_SET_IMAGE_VIEWER_FILE_INFO_MODAL_SHOW_BOOL, FEED_SET_CATEGORY_ID, FEED_SET_POST_CATEGORIES, FEED_SET_ARRAY_PROPERTY_FOR_POSTS, FEED_SET_EDIT_CATEGORIES_MODAL_SHOW_BOOL, FEED_SET_CATEGORY_INDEX, FEED_SET_NO_PLACE_MODAL_SHOW_BOOL, FEED_SET_AMOUNT_OF_MISSING_MEMORY, FEED_SET_POST_REQUEST_BOOL, FEED_SET_INITIAL_STATE} from './../../actions/feed/feedTypes';

const initialState = {
    user: "",
    lastModAvatarImage : Date.now(),
    userForFavoriteFeedsSwitcher: false,
    favorite: false,
    privateShow: false,
    searchWord: "",
    categoryId: "all",
    categoryIndex: 0,
    postCategories: "",
    arrayPropertyForPosts: "",
    posts : "",
    lastIdOfPost: "",
    iterableBlockerScroll: 0,
    newPostsCount: 0,
    newPostsIdMassive: "",
    newPostsIdMassiveFromServer: "",
    delayNotificationOfNewPosts: false,
    imageViewerShowBool: false,
    menuPostModalShowBool: false,
    captchaModalShowBool: false,
    headerMobileModalShowBool: false,
    menuPostModal: "",
    imageViewerPost: "",
    imageViewerIndexFile: -1,
    imageViewerIndexFileForLoading: -1,
    commentModalShowBool: false,
    commentModalPostId: "",
    commentModalComments: "",
    socketId: "",
    anyWindowIsOpen: false,
    captchaNanoIdPass: "",
    headerMobileModalFinderFeedShowBool: false,
    modalAvatarEditShowBool: false,
    shareModalShowBool: false,
    entityForModalShare: "",
    uploadPostModalShowBool: false,
    imageViewerFileInfoModalShowBool: false,
    editCategoriesModalShowBool: false,
    noPlaceModalShowBool: false,
    amountOfMissingMemory: 0,
    postRequestBool: false
}

export const feedReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case FEED_SET_USER:
            return {...state, user: payload.user };
        case FEED_SET_LAST_MOD_AVATAR_IMAGE:
            return {...state, lastModAvatarImage: payload.lastModAvatarImage };
        case FEED_SET_USER_FOR_FAVORITE_FEEDS_SWITCHER:
            return {...state, userForFavoriteFeedsSwitcher: payload.userForFavoriteFeedsSwitcher };
        case FEED_SET_USERINFOPANEL_FAVORITE_CHECKER:
            return {...state, favorite: payload.favorite };
        case FEED_SET_USERINFOPANEL_PRIVATE_SHOW_CHECKER:
            return {...state, privateShow: payload.privateShow };
        case FEED_SET_USERINFOPANEL_SEARCHWORD:
            return {...state, searchWord: payload.searchWord };
        case FEED_SET_CATEGORY_ID:
            return {...state, categoryId: payload.categoryId };
        case FEED_SET_CATEGORY_INDEX:
            return {...state, categoryIndex: payload.categoryIndex };
        case FEED_SET_POST_CATEGORIES:
            return {...state, postCategories: payload.postCategories };
        case FEED_SET_ARRAY_PROPERTY_FOR_POSTS:
            return {...state, arrayPropertyForPosts: payload.arrayPropertyForPosts };
        case FEED_SET_POSTS:
            return {...state, posts: payload.posts };
        case FEED_SET_LASTID_OF_POST:
            return {...state, lastIdOfPost: payload.lastIdOfPost };
        case FEED_SET_ITERABLE_BLOCKER_SCROLL:
            return {...state, iterableBlockerScroll: payload.iterableBlockerScroll };
        case FEED_SET_NEWPOSTSCOUNT:
            return {...state, newPostsCount: payload.newPostsCount };
        case FEED_SET_NEW_POSTS_ID_MASSIVE:
            return {...state, newPostsIdMassive: payload.newPostsIdMassive };
        case FEED_SET_NEW_POSTS_ID_MASSIVE_FROM_SERVER:
            return {...state, newPostsIdMassiveFromServer: payload.newPostsIdMassiveFromServer };
        case FEED_SET_DELAY_NOTIFICATION_OF_NEW_POSTS:
            return {...state, delayNotificationOfNewPosts: payload.delayNotificationOfNewPosts };
        case FEED_SET_IMAGE_VIEWER_POST:
            return {...state, imageViewerPost: payload.imageViewerPost };
        case FEED_SET_MENU_POST_MODAL:
            return {...state, menuPostModal: payload.menuPostModal };
        case FEED_SET_IMAGE_VIEWER_INDEX_FILE:
            return {...state, imageViewerIndexFile: payload.imageViewerIndexFile };
        case FEED_SET_IMAGE_VIEWER_INDEX_FILE_FOR_LOADING:
            return {...state, imageViewerIndexFileForLoading: payload.imageViewerIndexFileForLoading };
        case FEED_SET_IMAGE_VIEWER_SHOW_BOOL:
            return {...state, imageViewerShowBool: payload.imageViewerShowBool };
        case FEED_SET_COMMENT_MODAL_SHOW_BOOL:
            return {...state, commentModalShowBool: payload.commentModalShowBool };
        case FEED_SET_MENU_POST_MODAL_SHOW_BOOL:
            return {...state, menuPostModalShowBool: payload.menuPostModalShowBool };
        case FEED_SET_CAPTCHA_MODAL_SHOW_BOOL:
            return {...state, captchaModalShowBool: payload.captchaModalShowBool };
        case FEED_SET_HEADER_MOBILE_MODAL_SHOW_BOOL:
            return {...state, headerMobileModalShowBool: payload.headerMobileModalShowBool };
        case FEED_SET_COMMENT_MODAL_POST_ID:
            return {...state, commentModalPostId: payload.commentModalPostId };
        case FEED_SET_COMMENT_MODAL_COMMENTS:
            return {...state, commentModalComments: payload.commentModalComments };
        case FEED_SET_SOCKET_ID:
            return {...state, socketId: payload.socketId };
        case FEED_SET_ANY_WINDOW_IS_OPEN:
            return {...state, anyWindowIsOpen: payload.anyWindowIsOpen };
        case FEED_SET_ANY_CAPTCHA_NANO_ID_PASS:
            return {...state, captchaNanoIdPass: payload.captchaNanoIdPass };
        case FEED_SET_HEADER_MOBILE_MODAL_FINDER_FEED_SHOW_BOOL:
            return {...state, headerMobileModalFinderFeedShowBool: payload.headerMobileModalFinderFeedShowBool };
        case FEED_SET_MODAL_AVATAR_EDIT_SHOW_BOOL:
            return {...state, modalAvatarEditShowBool: payload.modalAvatarEditShowBool };
        case FEED_SET_SHARE_MODAL_SHOW_BOOL:
            return {...state, shareModalShowBool: payload.shareModalShowBool };
        case FEED_SET_ENTITY_FOR_MODAL_SHARE:
            return {...state, entityForModalShare: payload.entityForModalShare };
        case FEED_SET_UPLOAD_POST_MODAL_SHOW_BOOL:
            return {...state, uploadPostModalShowBool: payload.uploadPostModalShowBool };
        case FEED_SET_IMAGE_VIEWER_FILE_INFO_MODAL_SHOW_BOOL:
            return {...state, imageViewerFileInfoModalShowBool: payload.imageViewerFileInfoModalShowBool };
        case FEED_SET_EDIT_CATEGORIES_MODAL_SHOW_BOOL:
            return {...state, editCategoriesModalShowBool: payload.editCategoriesModalShowBool };
        case FEED_SET_NO_PLACE_MODAL_SHOW_BOOL:
            return {...state, noPlaceModalShowBool: payload.noPlaceModalShowBool };
        case FEED_SET_AMOUNT_OF_MISSING_MEMORY:
            return {...state, amountOfMissingMemory: payload.amountOfMissingMemory };
        case FEED_SET_POST_REQUEST_BOOL:
            return {...state, postRequestBool: payload.postRequestBool };
        case FEED_SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};