import { changeFeedAccessMode, changeQuickSearchForYourName, getUserDevicesCount } from "../../../services/userRequestsService"
import { appAsyncSetAuthUser } from "../app/appActions"
import { CHANGE_PASSWORD_MODAL_SHOW_BOOL, CONFIDENTIALITY_MODAL_SHOW_BOOL, DEVICES_COUNT, DEVICES_MODAL_SHOW_BOOL, FAVORITE_FEEDS_MODAL_SHOW_BOOL, GENERAL_SETTINGS_MODAL_SHOW_BOOL, REMOVE_FEED_MODAL_SHOW_BOOL, WHO_CAN_USE_MY_FEED_MODAL_SHOW_BOOL } from "./settingsTypes"

export const settingsSetDevicesCount = (devicesCount) =>{
    return{
        type: DEVICES_COUNT,
        payload:{
            devicesCount
        }
    }

}

export const settingsSetGeneralSettingsModalShowBool = (generalSettingsModalShowBool) =>{
    return{
        type: GENERAL_SETTINGS_MODAL_SHOW_BOOL,
        payload:{
            generalSettingsModalShowBool
        }
    }

}

export const settingsSetConfidentialityModalShowBool = (confidentialityModalShowBool) =>{
    return{
        type: CONFIDENTIALITY_MODAL_SHOW_BOOL,
        payload:{
            confidentialityModalShowBool
        }
    }

}

export const settingsSetFavoriteFeedsModalShowBool = (favoriteFeedsModalShowBool) =>{
    return{
        type: FAVORITE_FEEDS_MODAL_SHOW_BOOL,
        payload:{
            favoriteFeedsModalShowBool
        }
    }

}

export const settingsSetDevicesModalShowBool = (devicesModalShowBool) =>{
    return{
        type: DEVICES_MODAL_SHOW_BOOL,
        payload:{
            devicesModalShowBool
        }
    }
}

export const settingsSetChangePasswordModalShowBool = (changePasswordModalShowBool) =>{
    return{
        type: CHANGE_PASSWORD_MODAL_SHOW_BOOL,
        payload:{
            changePasswordModalShowBool
        }
    }
}

export const settingsSetRemoveFeedModalShowBool = (removeFeedModalShowBool) =>{
    return{
        type: REMOVE_FEED_MODAL_SHOW_BOOL,
        payload:{
            removeFeedModalShowBool
        }
    }
}

export const settingsSetWhoCanUseMyFeedModalShowBool = (whoCanUseMyFeedModalShowBool) =>{
    return{
        type: WHO_CAN_USE_MY_FEED_MODAL_SHOW_BOOL,
        payload:{
            whoCanUseMyFeedModalShowBool
        }
    }
}


export const settingsAsyncSetChangeQuickSearchForYourName = (quickSearchForYourName) =>{
    return function (dispatch, getState){
        changeQuickSearchForYourName(quickSearchForYourName)
            .then(response => {
                console.log(response.data)
                dispatch(appAsyncSetAuthUser());
            })
            .catch(e => {
                console.log(e);
            });
        }
}

export const settingsAsyncChangeFeedAccessMode = (feedAccessMode) =>{
    return function (dispatch, getState){
        changeFeedAccessMode(feedAccessMode)
            .then(response => {
                console.log(response.data)
                dispatch(appAsyncSetAuthUser());
            })
            .catch(e => {
                console.log(e);
            });
        }
}

export const settingsAsyncSetDevicesCount = () =>{
    return function (dispatch, getState){
        getUserDevicesCount()
            .then(response => {
                console.log(response.data)
                dispatch(settingsSetDevicesCount(response.data.devicesCount));
            })
            .catch(e => {
                console.log(e);
            });
        }
}
