import React, {Fragment, useEffect, Component, useRef, useState} from "react";
import Draggable from 'react-draggable';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getNewCaptcha, tryingToSolve } from "../../../services/captchaService";
import { feedSetCaptchaModalShowBool, feedSetCaptchaNanoIdPass } from "../../../store/actions/feed/feedActions";
import './captcha.scss';

import SVG from 'react-inlinesvg';
import times from '../../feeds/media/imageVewport/times.svg';
import syncAlt from '../../feeds/media/imageVewport/sync-alt.svg';
import lineVerticale from '../../feeds/media/captcha/bars.svg';
import arrowsAlt from '../../feeds/media/captcha/arrows-alt-h.svg';
import check from '../../feeds/media/captcha/check.svg';
import arrowDown from '../../feeds/media/captcha/angle-double-down.svg';

var nanoId = "";
// var firstPicture = "https://xakep.ru/wp-content/uploads/2018/09/185383/URL.jpeg"
// var secondPicture = "https://xakep.ru/wp-content/uploads/2018/09/185383/URL.jpeg"
var calcWidthHandler = 80;
var positionX = 0;
export const Captcha = () => {
    const dispatch = useDispatch();
    const captchaModalShowBool = useSelector((state) => state.feed.captchaModalShowBool, shallowEqual);

    const firstPartCaptchaRef = useRef(null);
    const secondPartCaptchaRef = useRef(null);
    const windowCaptcha = useRef(null);
    const animReloadCaptcha = useRef(null);
    const lineVerticaleRef = useRef(null);
    const arrowsAltRef = useRef(null);
    const captchaError = useRef(null);
    const captchaSuccess = useRef(null);
    const captchaAttemptsExceeded = useRef(null);
    const backgroundCaptchaMainBlockRef = useRef(null);
    const handleRectangleRef = useRef(null);
    const imageCaptchaBlockSVGRef = useRef(null);
    const closeCaptcha = useRef(null);
    const arrowDownRef = useRef(null);
    const captchaSliderBlockRef = useRef(null);

    function newCaptcha() {
        imageCaptchaBlockSVGRef.current.style.opacity = "1";
        animReloadCaptcha.current.style.animationPlayState = 'running';
        firstPartCaptchaRef.current.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        secondPartCaptchaRef.current.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        getNewCaptcha()
            .then(response => {
                // console.log(response.data);
                nanoId = response.data.nanoId;
                firstPartCaptchaRef.current.src = 'data:image/png;base64,' + response.data.firstPartCaptcha;
                // secondPicture = 'data:image/png;base64,' + response.data.secondPartCaptcha;
                secondPartCaptchaRef.current.src = 'data:image/png;base64,' + response.data.secondPartCaptcha;
                animReloadCaptcha.current.style.animationPlayState = 'paused';
                imageCaptchaBlockSVGRef.current.style.opacity = "0";
                revertPuzzleToDefaultPosition();
                // console.log(firstPartCaptchaRef.current.width * response.data.maxSizePuzzleElement/response.data.widthBaseImage);
                
                calcWidthHandler = firstPartCaptchaRef.current.width * response.data.maxSizePuzzleElement/response.data.widthBaseImage;
                handleRectangleRef.current.style.transition = "width .2s";
                handleRectangleRef.current.style.width = `${calcWidthHandler}px`;
                
                arrowDownRef.current.style.left = `${calcWidthHandler/2-15}px`;
                arrowDownRef.current.style.opacity = '1';

                setCaptchaWidth(firstPartCaptchaRef.current.width - calcWidthHandler);
            })
            .catch(e => {
                closeCaptcha.current.style.pointerEvents = "";
                // console.log(e);
            });
    }

    function handleStart(){
        // console.log('start');
        lineVerticaleRef.current.style.opacity = "0";
        arrowsAltRef.current.style.opacity = "1";
    }

    function handleDrag(e, data){
        // console.log('drag')
        positionX = data.x;
        handleRectangleRef.current.style.transform = `translateX(${data.x}px)`;
        // setPositionX(data.x)
        secondPartCaptchaRef.current.style.transform = `translateX(${data.x}px)`;
    }

    function tryingCaptcha(nanoId, presumptiveAnswer){
        handleRectangleRef.current.style.pointerEvents = "none";
        captchaSliderBlockRef.current.style.opacity = ".5";

        animReloadCaptcha.current.style.pointerEvents = "none";
        closeCaptcha.current.style.pointerEvents = "none";
        tryingToSolve(nanoId, presumptiveAnswer)
            .then(response => {
                // console.log(response.data);
                if (response.data.resolved){
                   
                    captchaSuccess.current.classList.add("captchaSuccess");
                    setTimeout(() => {
                        dispatch(feedSetCaptchaNanoIdPass(response.data.nanoId));
                        localStorage.setItem('captchaNanoIdPass', response.data.nanoId);
                        closeCaptchaWindow();
                    }, 1500);
                    
                }
                else{
                    //
                    
                    
                    // revertPuzzleToDefaultPosition();
                    if (response.data.attemptsExceeded){
                        captchaAttemptsExceeded.current.classList.add("captchaAttemptsExceeded");
                        backgroundCaptchaMainBlockRef.current.classList.add("captchaErrorMainBlock");
                        
                    }
                    else{
                        captchaError.current.classList.add("captchaError");
                        backgroundCaptchaMainBlockRef.current.classList.add("captchaErrorMainBlock");
                    }
                }
            })
            .catch(e => {
                // console.log(e);
            });
    }


    const handleStop = (e, data) => {
        // console.log('Event Type', e.type);
        let valueForCaptcha = (data.x/firstPartCaptchaRef.current.width)*firstPartCaptchaRef.current.naturalWidth;
        tryingCaptcha(nanoId, valueForCaptcha);
        // console.log(firstPartCaptchaRef);
        // alert(data.x)
        // console.log(valueForCaptcha, "11111111111111111111111111111111111111");
        lineVerticaleRef.current.style.opacity = "1";
        arrowsAltRef.current.style.opacity = "0";
      }

    function closeCaptchaWindow(){
        // console.log('close window');
        captchaSuccess.current.classList.remove("captchaSuccess");
        firstPartCaptchaRef.current.src = "";
        // secondPicture = "https://xakep.ru/wp-content/uploads/2018/09/185383/URL.jpeg"
        secondPartCaptchaRef.current.src = "";
        dispatch(feedSetCaptchaModalShowBool(false));
        revertPuzzleToDefaultPosition();
        captchaError.current.classList.remove("captchaError");
        backgroundCaptchaMainBlockRef.current.classList.remove("captchaErrorMainBlock");
        closeCaptcha.current.style.pointerEvents = "";
    }


    useEffect(() => {
        if (captchaModalShowBool){
            // document.body.classList.add("modalBlocOverflow");
            windowCaptcha.current.style.display = "flex";
            imageCaptchaBlockSVGRef.current.style.height = `${imageCaptchaBlockSVGRef.current.clientWidth/1.66}px`;
            newCaptcha();
            // menuPostDesktopBGRef.current.classList.add("OpenModalAnim");
            // menuPostDesktopRef.current.classList.add("OpenModalTransAnim");
            // document.body.classList.add("modalBlocOverflow");
        }
        else{
            windowCaptcha.current.style.display = "none";
            // document.body.classList.remove("modalBlocOverflow");
        }
    },[captchaModalShowBool]);

    function handleAnimationEndAttemptsExceeded(){
        captchaAttemptsExceeded.current.classList.remove("captchaAttemptsExceeded");
        backgroundCaptchaMainBlockRef.current.classList.remove("captchaErrorMainBlock");
        newCaptcha();
    }

    function handleAnimationEnd() {
        // console.log("animOFF");
        revertPuzzleToDefaultPosition();
        captchaError.current.classList.remove("captchaError");
        backgroundCaptchaMainBlockRef.current.classList.remove("captchaErrorMainBlock");
    }

    function revertPuzzleToDefaultPosition(){
        // setKeyValueForDraggable(keyValueForDraggable + 1);
        // setPositionX(0);
        // setCaptchaWidth(0);
        handleRectangleRef.current.style.transition = ".2s";
        secondPartCaptchaRef.current.style.transition = ".2s";
        positionX = 0;
        setTimeout(() => {
            handleRectangleRef.current.style.transition = "";
            secondPartCaptchaRef.current.style.transition = "";
            handleRectangleRef.current.style.pointerEvents = "";
            captchaSliderBlockRef.current.style.opacity = "1";
            animReloadCaptcha.current.style.pointerEvents = "";
            closeCaptcha.current.style.pointerEvents = "";
        }, 200);
        handleRectangleRef.current.style.transform = `translateX(${0}px)`;
        handleRectangleRef.current.style.width = `${calcWidthHandler}px`;
        secondPartCaptchaRef.current.style.transform = `translateX(${0}px)`;

    }

    // const keyValueForDraggable = useRef(null);
    // const [keyValueForDraggable, setKeyValueForDraggable] = useState(0);
    // const [positionX, setPositionX] = useState(0);
    const [captchaWidth, setCaptchaWidth] = useState(0);

    // useEffect(() => {
    //     console.log(captchaWidth, 'captchaWidth');
    // },[captchaWidth]);

    return(
        <Fragment>
                
            <div ref={windowCaptcha}  className="backgroundCaptcha">
                {/* <div onClick = {closeCaptchaWindow}  className="backgroundCaptcha__close">
                    <SVG className="backgroundCaptcha__close__button" src={times}/>
                </div> */}
                    
                <div ref={backgroundCaptchaMainBlockRef} className="backgroundCaptchaMainBlock">
                
                   <div className="backgroundCaptchaBlock">
                    <span style={{marginBottom:"15px", fontSize:"18px"}}>Сдвиньте пазл на место</span>
                    <div className="imageCaptchaBlock">
                        
                        
                        <img ref = {firstPartCaptchaRef} className ="imageCaptcha" src="" alt=""/>
                        
                        
                            
                                {/* <div className="handle">Drag from here</div> */}
                        <img ref = {secondPartCaptchaRef}  className ="imageCaptcha _position" src="" alt=""/>
                                {/* <div>This readme is really dragging on...</div> */}
                            
                        <div ref={arrowDownRef} className="arrowDown">
                            <SVG src={arrowDown}/>
                        </div>
                        
                        <div ref={imageCaptchaBlockSVGRef} className="imageCaptchaBlockSVG">
                            <SVG className="backgroundCaptcha__close__button reloadCaptcha" src={syncAlt}/>
                        </div>
                        
                        
                        <div onAnimationEnd={handleAnimationEnd} ref={captchaError} className="imageCaptchaError">Поместите деталь в паз.</div>
                        <div onAnimationEnd={handleAnimationEndAttemptsExceeded} ref={captchaAttemptsExceeded} className="imageCaptchaAttemptsExceeded">Попытки превышены</div>
                        <div ref={captchaSuccess} className="imageCaptchaSuccess">
                            <SVG src={check} />
                        </div>
                    </div>

                    <div ref={captchaSliderBlockRef} className="captchaSliderBlock">
                        {/* <span>Сдвиньте слайдер на место</span> */}
                            <Draggable
                                // ref={draggableEntity}
                                axis="x"
                                handle=".handle"
                                defaultPosition={{x: 0, y: 0}}
                                bounds={{left: 0, top: 0, right: captchaWidth, bottom: 100}}
                                position={{x: positionX}}
                                    // y: yourPostion.y
                                  
                                    
                                // grid={[25, 25]}
                                scale={1}
                                onStart={handleStart}
                                onDrag={handleDrag}
                                onStop={handleStop}>

                                    <div ref = {handleRectangleRef} className="captchaSliderCircle handle">
                                        <div ref={lineVerticaleRef} className="_trans"><SVG style={{transform: "rotateZ(90deg)"}} src={lineVerticale}/></div>
                                        <div ref={arrowsAltRef} className="_trans" style={{opacity:"0"}}><SVG src={arrowsAlt}/></div>
                                    </div>
                            </Draggable>
                            

                    </div>

                    <div className="backgroundCaptchaBottomButton">
                        {/* <button onClick = {newCaptcha} className = "buttonNewCaptcha">start</button> */}
                        {/* <div className="button mr-3">
                            <SVG className="backgroundCaptcha__close__button" src={times}/>
                        </div> */}
                        <div ref={animReloadCaptcha} className="button" onClick = {newCaptcha}>
                            <SVG style={{width:"20px"}} className="backgroundCaptcha__close__button" src={syncAlt}/>
                        </div>
                        <div ref={closeCaptcha} onClick = {closeCaptchaWindow} className="button ml-4">
                            <SVG className="backgroundCaptcha__close__button" src={times}/>
                        </div>
                    </div>

                   </div>
                    
                </div>

            </div>
            
        </Fragment>
    )
}