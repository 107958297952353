import {getAllUrlsForDownloadPost, getUrlForDownload, postDownloadApi} from '../../../../services/filesService';
import { BASE_URL_STORAGE } from '../../../../services/urlConstants';


export function bytesToSize(bytes, decimals=2) {
    if (bytes == 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function toDateTime(secs) {
    let masMonthsRus = ["янв","фев","мар","апр","май","июн","июл","авг","сен","окт","ноя","дек"];
    let masMonthsEn = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var d = new Date(secs);
    //27.01.2022 17:31
    // var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
    //     d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    
    //27 янв 2022 в 17:31
    // var datestring = d.getDate() + " " + masMonthsRus[d.getMonth()] + " " + userYear +
    //     " в " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

    let actualYear = new Date().getFullYear();
    let userYear = d.getFullYear();
    if (actualYear > userYear){
        var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }
    else{
        var datestring = d.getDate() + " " + masMonthsRus[d.getMonth()] +
        " в " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }
    return datestring;
}

export function getExt(filename){
    if (filename){
        var ext = filename.split('.').pop();
        if(ext == filename) return "none";
        return ext;
    }
}

export function downloadFile(file){
    getUrlForDownload(file.postIdentifier, file.identifier)
        .then(response => {
            console.log(response.data);
            var link = document.createElement('a');
            link.href = response.data.url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
        })
        .catch(e => {
            console.log(e);
        });

}


function openWindowWithPost(url, data) {
    var form = document.createElement("form");
    form.target = "_top";
    form.method = "POST";
    form.action = url;
    form.style.display = "none";

    for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

export function downloadPost(postIdentifier, text){
    getAllUrlsForDownloadPost(postIdentifier)
        .then(response => {
            // console.log(response.data);
            let urlsWithNames = response.data;

            // window.open("http://localhost:5000/api/postDownloadApi");
            let urlRequest = BASE_URL_STORAGE + ":5010/api/postDownloadApi"

            openWindowWithPost(urlRequest, {
                urlsWithNames: JSON.stringify(urlsWithNames),
                text: text,
                // coords: encodeURIComponent(coords)
            });

            // postDownloadApi(urlsWithNames)
            //     .then(response2 => {
            //         // console.log(response2);
            //         // console.log('start zip archive');
            //         const downloadUrl = window.URL.createObjectURL(new Blob([response2.data]));
            //         // console.log('finish zip archive');
            //         const link = document.createElement('a');

            //         link.href = downloadUrl;

            //         link.setAttribute('download', 'files.zip'); //any other extension

            //         document.body.appendChild(link);

            //         link.click();

            //         link.remove();

            //     })
            //     .catch(e => {
            //         console.log(e);
            //     });


            
        
           ;
        })
        .catch(e => {
            console.log(e);
        });
}

