import { favoriteFeedsAddFeed, favoriteFeedsDeleteFeed, getFavoriteFeeds, getUserByToken } from '../../../services/userRequestsService';
import { feedSetUserForFavoriteFeedsSwitcher } from '../feed/feedActions'
import { APP_SET_AUTH_USER, APP_SET_FAVORITE_FEEDS, APP_SET_GENERAL_FEED_NAME, APP_SET_MODAL_VIDEO_INSTRUCTION_SHOW_BOOL, APP_SET_RECOVERY_FEED_MODAL_SHOW_BOOL } from './appTypes';

export const appSetAuthUser = (authUser) =>{
    return{
        type: APP_SET_AUTH_USER,
        payload:{
            authUser
        }
    }

}

export const appSetFavoriteFeeds = (favoriteFeeds) =>{
    return{
        type: APP_SET_FAVORITE_FEEDS,
        payload:{
            favoriteFeeds
        }
    }

}

export const appSetGeneralFeedName = (generalFeedName) =>{
    return{
        type: APP_SET_GENERAL_FEED_NAME,
        payload:{
            generalFeedName
        }
    }

}

export const appSetModalVideoInstruction = (modalVideoInstructionShowBool) =>{
    return{
        type: APP_SET_MODAL_VIDEO_INSTRUCTION_SHOW_BOOL,
        payload:{
            modalVideoInstructionShowBool
        }
    }

}

export const appSetRecoveryFeedModalShowBool = (recoveryFeedModalShowBool) =>{
    return{
        type: APP_SET_RECOVERY_FEED_MODAL_SHOW_BOOL,
        payload:{
            recoveryFeedModalShowBool
        }
    }

}

export const appAsyncSetAuthUser = () =>{
    return function (dispatch){
        getUserByToken()
            .then(response => {
                if (response.data.token === "empty"){
                    console.log(response.data, 'authUserCall');
                    localStorage.setItem('token', "");
                    // dispatch(appSetAuthUser('empty'));
                }
                else{
                    console.log(response.data, "auth");
                    dispatch(appSetAuthUser(response.data));
                    dispatch(appAsyncSetFavoriteFeeds());
                }
            })
            .catch(e => {
                console.log('notAuth');
                console.log(e);
                // if (authUser){
                //     console.log("error notAuth");
                // }
            });
    }
}

export const appAsyncSetFavoriteFeeds = () =>{
    return function (dispatch){
        getFavoriteFeeds()
            .then(response => {
                dispatch(appSetFavoriteFeeds(response.data));
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export const asyncFavoriteFeedsAddFeed = (userId) =>{
    return function (dispatch){
        favoriteFeedsAddFeed(userId)
            .then(response => {
                dispatch(feedSetUserForFavoriteFeedsSwitcher(false));
                dispatch(appAsyncSetFavoriteFeeds());
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export const asyncFavoriteFeedsDeleteFeed = (userId) =>{
    return function (dispatch){
        favoriteFeedsDeleteFeed(userId)
            .then(response => {
                dispatch(feedSetUserForFavoriteFeedsSwitcher(true));
                dispatch(appAsyncSetFavoriteFeeds());
            })
            .catch(e => {
                console.log(e);
            });
    }
}
