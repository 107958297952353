import React, { lazy, Suspense } from "react";

import {
  BrowserRouter as Router,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory
} from "react-router-dom";
import { useState, useEffect } from "react";

import { Switch, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { feedSetCaptchaNanoIdPass, feedSetCloseAllModalWindow, feedSetInitialState, feedSetLastModAvatarImage } from "../store/actions/feed/feedActions";
import { RemoveScroll } from "react-remove-scroll";
import { AnyWindowIsOpenDetector } from "./general/anyWindowIsOpen/anyWindowIsOpen";

import ResizeObserver from 'resize-observer-polyfill';
import { Captcha } from "./general/captcha/captcha";
import { Redirect } from "react-router-dom/cjs/react-router-dom";


// const exclusionArray = [
//   // 'signin',
//   // 'registration',
//   // 'forgotPassword',
//   // 'createNewPassword'
// ]

const SupportMaster = lazy(() => import ('./support/supportMaster'));
const WelcomePageMaster = lazy(() => import ('./welcomePageNew/welcomePageMaster'));
const RegistrationMaster = lazy(() => import ('./sign-in-up-New/registration/registrationMaster'));
const SignInMaster = lazy(() => import ('./sign-in-up-New/signin/signinMaster'));
const ForgotPasswordMaster = lazy(() => import ('./sign-in-up-New/forgotPassword/forgotPasswordMaster'));
const CreateNewPasswordMaster = lazy(() => import ('./sign-in-up-New/createNewPassword/createNewPasswordMaster'));
const Feed = lazy(() => import ('./feeds/feed'));
const SettingsMaster = lazy(() => import ('./feeds/settings/settingsMaster'));
const TariffPlans = lazy(() => import ('./feeds/tariffPlans/tariffPlans'));
const SharedMain = lazy(() => import ('./shared/sharedMain'));
const FeedHeaderMaster = lazy(() => import ('./feeds/feedheader/feedHeaderMaster'));
const FooterMaster = lazy(() => import ('./general/footer/footerMaster'));

const App = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const anyWindowIsOpen = useSelector((state) => state.feed.anyWindowIsOpen, shallowEqual);
  
  useEffect(() => {
    if (!window.ResizeObserver){
      window.ResizeObserver = ResizeObserver
    }
  },[])

  //------------------------------------------------- Closing windows on return--------------------------------------------------------------
  useEffect(() => {
      // dispatch(feedSetLastModAvatarImage(Date.now()));
      if (history.action === "POP") {
        // Do your stuff
        dispatch(feedSetCloseAllModalWindow());
      }
    },[location])

    useEffect(() => {
      dispatch(feedSetCloseAllModalWindow());
      dispatch(feedSetInitialState());

      setTimeout(() => {
        let captchaNanoIdPass = localStorage.getItem('captchaNanoIdPass');
        if (captchaNanoIdPass){
            dispatch(feedSetCaptchaNanoIdPass(captchaNanoIdPass));
        }
      }, 1);
      
      console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;','change location.pathname', location.pathname);
    },[location.pathname])
    

  useEffect(() => {
    console.log(anyWindowIsOpen, 'anyWindowIsOpen');
    if (anyWindowIsOpen){
      history.push(location.pathname, { from: 'anyWindowIsOpen' });
    }
    else{
      if (history.location.state){
        if (history.location.state.from == 'anyWindowIsOpen'){
          history.goBack();
        }
      }
    }
    console.log(history.length); 
  },[anyWindowIsOpen])

  //------------------------------------------------- Closing windows on return--------------------------------------------------------------

  return(
          <>
            {console.log('reload_app')}
            <RemoveScroll enabled={anyWindowIsOpen} className="w-100">
              <AnyWindowIsOpenDetector/>
              <Captcha/>
              {/* {exclusionArray.indexOf(location.pathname.split('/')[1]) < 0 && <FeedHeaderMaster pathname = {location.pathname} />} */}
              <FeedHeaderMaster pathname = {location.pathname} />
              <FooterMaster pathname = {location.pathname}/>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
                <Switch>
                  <Route path="/" exact component = {WelcomePageMaster}></Route>
                  <Route exact path="/index.html" render={() => (<Redirect to="/" />)} />
                  <Route path="/registration" exact component = {RegistrationMaster}></Route>
                  <Route path="/signin" exact component = {SignInMaster}></Route>
                  <Route path="/forgotPassword" exact component = {ForgotPasswordMaster}></Route>
                  <Route path="/createNewPassword/:tokenOfUser" exact component = {CreateNewPasswordMaster}></Route>
                  <Route path="/settings" exact component = {SettingsMaster}></Route>
                  <Route path="/tariffPlans" exact component = {TariffPlans}></Route>
                  <Route path="/support" exact component = {SupportMaster}></Route>
                  <Route path="/s/:hashUrl" exact component = {SharedMain}></Route>
                  <Route path="/:username" exact component = {Feed}></Route>
                </Switch>
              {/* </Suspense> */}
            </RemoveScroll>
          </>
      );
  }
export default App;