import { SUPPORT_SET_MENU_ITEM, SUPPORT_SET_MOBILE_MENU_ITEMS_SHOW_BOOL, SUPPORT_SET_SCROLL_TO_ELEMENT } from "../../actions/support/supportTypes";

const initialState = {
    menuItem: 0,
    scrollToElement: null,
    mobileMenuItemsShowBool: false
};

export const supportReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SUPPORT_SET_MENU_ITEM:
            return {...state, menuItem: payload.menuItem };
        case SUPPORT_SET_SCROLL_TO_ELEMENT:
            return {...state, scrollToElement: payload.scrollToElement };
        case SUPPORT_SET_MOBILE_MENU_ITEMS_SHOW_BOOL:
            return {...state, mobileMenuItemsShowBool: payload.mobileMenuItemsShowBool };
        default:
            return state;
    }
};