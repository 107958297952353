import { CHANGE_PASSWORD_MODAL_SHOW_BOOL, CONFIDENTIALITY_MODAL_SHOW_BOOL, DEVICES_COUNT, DEVICES_MODAL_SHOW_BOOL, FAVORITE_FEEDS_MODAL_SHOW_BOOL, GENERAL_SETTINGS_MODAL_SHOW_BOOL, REMOVE_FEED_MODAL_SHOW_BOOL, WHO_CAN_USE_MY_FEED_MODAL_SHOW_BOOL } from "../../actions/settings/settingsTypes";


const initialState = {
    devicesCount: 0,
    generalSettingsModalShowBool: false,
    confidentialityModalShowBool: false,
    favoriteFeedsModalShowBool: false,
    devicesModalShowBool: false,
    changePasswordModalShowBool: false,
    removeFeedModalShowBool: false,
    whoCanUseMyFeedModalShowBool: false
};

export const settingsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case DEVICES_COUNT:
            return {...state, devicesCount: payload.devicesCount };
        case GENERAL_SETTINGS_MODAL_SHOW_BOOL:
            return {...state, generalSettingsModalShowBool: payload.generalSettingsModalShowBool };
        case CONFIDENTIALITY_MODAL_SHOW_BOOL:
            return {...state, confidentialityModalShowBool: payload.confidentialityModalShowBool };
        case FAVORITE_FEEDS_MODAL_SHOW_BOOL:
            return {...state, favoriteFeedsModalShowBool: payload.favoriteFeedsModalShowBool };
        case DEVICES_MODAL_SHOW_BOOL:
            return {...state, devicesModalShowBool: payload.devicesModalShowBool };
        case CHANGE_PASSWORD_MODAL_SHOW_BOOL:
            return {...state, changePasswordModalShowBool: payload.changePasswordModalShowBool };
        case REMOVE_FEED_MODAL_SHOW_BOOL:
            return {...state, removeFeedModalShowBool: payload.removeFeedModalShowBool };
        case WHO_CAN_USE_MY_FEED_MODAL_SHOW_BOOL:
            return {...state, whoCanUseMyFeedModalShowBool: payload.whoCanUseMyFeedModalShowBool };
        default:
            return state;
    }
};